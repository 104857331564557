import * as d3 from 'd3';

export const collapseIcon = (
  x: number,
  y: number,
  svg: any,
  size: number,
  onClick: () => void,
  color: string
) => {
  const markerBoxWidth = 4;
  const markerBoxHeight = 4;
  const refX = markerBoxWidth / 2;
  const refY = markerBoxHeight / 2;
  const graphics = [];
  const arrowPoints: [number, number][] = [
    [1, 0],
    [1, 4],
    [3, 2],
  ];

  graphics.push(
    // Wrapper rectangle
    svg
      .append('rect')
      .attr('x', x - 2)
      .attr('y', y - 2)
      .attr('width', size + 4)
      .attr('height', size + 4)
      .attr('fill', 'transparent')
      .attr('stroke', '#263E48')
      .attr('cursor', 'pointer')
      .style('cursor', 'pointer')
  );
  graphics.push(
    // Downward line
    svg
      .append('defs')
      .append('marker')
      .attr('id', 'arrowDownExpand')
      .attr('viewBox', [0, 0, markerBoxWidth, markerBoxHeight])
      .attr('refX', refX)
      .attr('refY', refY)
      .attr('markerWidth', markerBoxWidth)
      .attr('markerHeight', markerBoxHeight)
      .attr('orient', 'auto-start-reverse')
      .attr('fill', color)
      .append('path')
      .attr('d', d3.line()(arrowPoints))
      .attr('stroke', 'none')
  );
  // Downward arrow
  graphics.push(
    svg
      .append('path')
      .attr(
        'd',
        d3.line()([
          [x + size / 2, y],
          [x + size / 2, y + size / 2 - 5],
        ])
      )
      .attr('stroke', color)
      .attr('marker-end', 'url(#arrowUpExpand)')
      .attr('fill', 'none')
      .attr('cursor', 'pointer')
      .attr('stroke-width', 1.5)
  );
  graphics.push(
    // Upward line
    svg
      .append('defs')
      .append('marker')
      .attr('id', 'arrowUpExpand')
      .attr('viewBox', [0, 0, markerBoxWidth, markerBoxHeight])
      .attr('refX', refX)
      .attr('refY', refY)
      .attr('markerWidth', markerBoxWidth)
      .attr('markerHeight', markerBoxHeight)
      .attr('orient', 'auto-start-reverse')
      .attr('fill', color)
      .append('path')
      .attr('d', d3.line()(arrowPoints))
      .attr('stroke', 'none')
  );
  // Upward arrow
  graphics.push(
    svg
      .append('path')
      .attr(
        'd',
        d3.line()([
          [x + size / 2, y + size],
          [x + size / 2, y + size / 2 + 5],
        ])
      )
      .attr('stroke', color)
      .attr('marker-end', 'url(#arrowDownExpand)')
      .attr('fill', 'none')
      .attr('cursor', 'pointer')
      .attr('stroke-width', 1.5)
  );

  // Horizontal line top
  graphics.push(
    svg
      .append('line')
      .attr('class', 'collapse-elem')
      .attr('x1', x + 3)
      .attr('y1', y + size / 2 - 1.5)
      .attr('x2', x + size - 3)
      .attr('y2', y + size / 2 - 1.5)
      .attr('cursor', 'pointer')
      .attr('stroke', color)
      .attr('stroke-width', 1.5)
  );

  // Horizontal line bottom
  graphics.push(
    svg
      .append('line')
      .attr('class', 'collapse-elem')
      .attr('x1', x + 3)
      .attr('y1', y + size / 2 + 1.5)
      .attr('x2', x + size - 3)
      .attr('y2', y + size / 2 + 1.5)
      .attr('cursor', 'pointer')
      .attr('stroke', color)
      .attr('stroke-width', 1.5)
  );
  graphics.forEach((g) => {
    g.on('click', function () {
      onClick();
    });
  });
};

export const expandIcon = (
  x: number,
  y: number,
  svg: any,
  size: number,
  onClick: () => void,
  color: string
) => {
  const markerBoxWidth = 4;
  const markerBoxHeight = 4;
  const refX = markerBoxWidth / 2;
  const refY = markerBoxHeight / 2;
  const graphics = [];
  const arrowPoints: [number, number][] = [
    [1, 0],
    [1, 4],
    [3, 2],
  ];

  graphics.push(
    // Wrapper rectangle
    svg
      .append('rect')
      .attr('x', x - 1)
      .attr('y', y - 1)
      .attr('x', x - 2)
      .attr('y', y - 2)
      .attr('width', size + 4)
      .attr('height', size + 4)
      .attr('fill', 'transparent')
      .attr('cursor', 'pointer')
      .attr('stroke', '#263E48')
  );
  graphics.push(
    // Downward line
    svg
      .append('defs')
      .append('marker')
      .attr('id', 'arrowDownExpand')
      .attr('viewBox', [0, 0, markerBoxWidth, markerBoxHeight])
      .attr('refX', refX)
      .attr('refY', refY)
      .attr('markerWidth', markerBoxWidth)
      .attr('markerHeight', markerBoxHeight)
      .attr('orient', 'auto-start-reverse')
      .attr('fill', color)
      .append('path')
      .attr('d', d3.line()(arrowPoints))
      .attr('stroke', 'none')
  );
  // Downward arrow
  graphics.push(
    svg
      .append('path')
      .attr(
        'd',
        d3.line()([
          [x + size / 2, y + size / 2],
          [x + size / 2, y + size - 3],
        ])
      )
      .attr('stroke', color)
      .attr('marker-end', 'url(#arrowUpExpand)')
      .attr('fill', 'none')
      .attr('cursor', 'pointer')
      .attr('stroke-width', 1.5)
  );
  graphics.push(
    // Upward line
    svg
      .append('defs')
      .append('marker')
      .attr('id', 'arrowUpExpand')
      .attr('viewBox', [0, 0, markerBoxWidth, markerBoxHeight])
      .attr('refX', refX)
      .attr('refY', refY)
      .attr('markerWidth', markerBoxWidth)
      .attr('markerHeight', markerBoxHeight)
      .attr('orient', 'auto-start-reverse')
      .attr('fill', color)
      .append('path')
      .attr('d', d3.line()(arrowPoints))
      .attr('stroke', 'none')
  );
  // Upward arrow
  graphics.push(
    svg
      .append('path')
      .attr(
        'd',
        d3.line()([
          [x + size / 2, y + size / 2],
          [x + size / 2, y + 3],
        ])
      )
      .attr('stroke', color)
      .attr('marker-end', 'url(#arrowDownExpand)')
      .attr('fill', 'none')
      .attr('cursor', 'pointer')
      .attr('stroke-width', 1.5)
  );

  // Horizontal line top
  graphics.push(
    svg
      .append('line')
      .attr('class', 'collapse-elem')
      .attr('x1', x + 3)
      .attr('y1', y)
      .attr('x2', x + size - 3)
      .attr('y2', y)
      .attr('cursor', 'pointer')
      .attr('stroke', color)
      .attr('stroke-width', 1.5)
  );

  // Horizontal line bottom
  graphics.push(
    svg
      .append('line')
      .attr('class', 'collapse-elem')
      .attr('x1', x + 3)
      .attr('y1', y + size)
      .attr('x2', x + size - 3)
      .attr('y2', y + size)
      .attr('cursor', 'pointer')
      .attr('stroke', color)
      .attr('stroke-width', 1.5)
  );
  graphics.forEach((g) => {
    g.on('click', function () {
      onClick();
    });
  });
};

export const chevronRightIcon = (
  x: number,
  y: number,
  svg: any,
  size: number,
  onClick: () => void,
  color: string
) => {
  const graphics = [];

  graphics.push(
    // Wrapper rectangle
    svg
      .append('rect')
      .attr('x', x - 1)
      .attr('y', y - 1)
      .attr('width', size + 2)
      .attr('height', size + 2)
      .attr('fill', 'transparent')
      .style('cursor', 'pointer')
  );
  graphics.push(
    // Downward line
    svg
      .append('path')
      .attr(
        'd',
        d3.line()([
          [x + size / 4, y],
          [x + (3 * size) / 4, y + size / 2],
          [x + size / 4, y + size],
        ])
      )
      .attr('stroke', color)
      .attr('stroke-width', 2)
      .attr('marker-start', 'url(#arrow)')
      .attr('marker-end', 'url(#arrow)')
      .attr('fill', 'none')
  );
  graphics.forEach((g) => {
    g.on('click', function () {
      onClick();
    });
  });
};

export const chevronDownIcon = (
  x: number,
  y: number,
  svg: any,
  size: number,
  onClick: () => void,
  color: string
) => {
  const graphics = [];

  graphics.push(
    // Wrapper rectangle
    svg
      .append('rect')
      .attr('x', x - 1)
      .attr('y', y - 1)
      .attr('width', size + 2)
      .attr('height', size + 2)
      .attr('fill', 'transparent')
      .style('cursor', 'pointer')
  );
  graphics.push(
    // Downward line
    svg
      .append('path')
      .attr(
        'd',
        d3.line()([
          [x, y + size / 4],
          [x + size / 2, y + (3 * size) / 4],
          [x + size, y + size / 4],
        ])
      )
      .attr('stroke', color)
      .attr('stroke-width', 2)
      .attr('marker-start', 'url(#arrow)')
      .attr('marker-end', 'url(#arrow)')
      .attr('fill', 'none')
  );
  graphics.forEach((g) => {
    g.on('click', function () {
      onClick();
    });
  });
};
