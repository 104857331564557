/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

'use client';

import { useContext } from 'react';
import { AppDetailsContext } from '@/context';

export const useAppDetails = () => {
  return useContext(AppDetailsContext);
};
