/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

import { LucideCheck } from 'lucide-react';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';

interface PresetButtonProps {
  label: string;
  isSelected: boolean;
  onClick: () => void;
}

export const PresetButton: React.FC<PresetButtonProps> = ({
  label,
  isSelected,
  onClick,
}) => (
  <Button
    className={cn(
      'w-full justify-start px-4 hover:bg-card-02',
      isSelected && 'pointer-events-none bg-[#34BCAF14]'
    )}
    variant="ghost"
    onClick={onClick}
  >
    <div className="flex w-full items-center">
      <div className={cn('w-6 opacity-0', isSelected && 'opacity-70')}>
        <LucideCheck className="text-jade" width={18} height={18} />
      </div>
      <span
        className={cn(
          'text-form-input-text text-body-subtle',
          isSelected && 'text-white'
        )}
      >
        {label}
      </span>
    </div>
  </Button>
);
