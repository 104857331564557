/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

import { DateRange } from './types';

export const formatDate = (date: Date, locale: string = 'en-us'): string => {
  return date.toLocaleDateString(locale, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

export const getDateAdjustedForTimezone = (dateInput: Date | string): Date => {
  if (typeof dateInput === 'string') {
    // Split the date string to get year, month, and day parts
    const parts = dateInput.split('-').map((part) => parseInt(part, 10));
    // Create a new Date object using the local timezone
    // Note: Month is 0-indexed, so subtract 1 from the month part
    const date = new Date(parts[0], parts[1] - 1, parts[2]);
    return date;
  } else {
    // If dateInput is already a Date object, return it directly
    return dateInput;
  }
};

// Helper function to check if two date ranges are equal
export const areRangesEqual = (a?: DateRange, b?: DateRange): boolean => {
  if (!a || !b) return a === b; // If either is undefined, return true if both are undefined
  return (
    (a.from?.getTime() === b.from?.getTime() || (!a.from && !b.from)) &&
    (a.to?.getTime() === b.to?.getTime() || (!a.to && !b.to))
  );
};
