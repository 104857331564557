/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
import { getPresetRange } from './presets';
import { DateRange, Preset } from './types';

export const setPreset = (
  preset: string,
  presetArray: Preset[],
  setRange: (range: DateRange) => void,
  rangeCompare: DateRange | undefined,
  setRangeCompare: (rangeCompare: DateRange | undefined) => void
): void => {
  // Skip processing if preset is allTime
  if (preset === 'allTime') {
    return;
  }

  const range = getPresetRange(preset, presetArray);
  setRange(range);
  if (rangeCompare && range.from && range.to) {
    const compareRange = {
      from: new Date(
        range.from.getFullYear() - 1,
        range.from.getMonth(),
        range.from.getDate()
      ),
      to: range.to
        ? new Date(
            range.to.getFullYear() - 1,
            range.to.getMonth(),
            range.to.getDate()
          )
        : undefined,
    };
    setRangeCompare(compareRange);
  }
};

export const checkPreset = (
  PRESETS: Preset[],
  range: DateRange,
  setSelectedPreset: (preset: string | undefined) => void
): void => {
  // Handle the case where range has undefined dates (All time)
  if (!range.from || !range.to) {
    setSelectedPreset('allTime');
    return;
  }

  for (const preset of PRESETS) {
    // Skip checking allTime preset
    if (preset.name === 'allTime') {
      continue;
    }

    const presetRange = getPresetRange(preset.name, PRESETS);

    // Skip if preset range has undefined dates
    if (!presetRange.from || !presetRange.to) {
      continue;
    }

    const normalizedRangeFrom = new Date(range.from);
    normalizedRangeFrom.setHours(0, 0, 0, 0);

    const normalizedPresetFrom = new Date(presetRange.from);
    normalizedPresetFrom.setHours(0, 0, 0, 0);

    const normalizedRangeTo = new Date(range.to);
    normalizedRangeTo.setHours(0, 0, 0, 0);

    const normalizedPresetTo = new Date(presetRange.to);
    normalizedPresetTo.setHours(0, 0, 0, 0);

    if (
      normalizedRangeFrom.getTime() === normalizedPresetFrom.getTime() &&
      normalizedRangeTo.getTime() === normalizedPresetTo.getTime()
    ) {
      setSelectedPreset(preset.name);
      return;
    }
  }

  setSelectedPreset(undefined);
};
