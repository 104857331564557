/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import React from 'react';
import { useLanguage } from '@/providers/LanguageProvider';
import { formatDateMonthTime } from '@/utils/formatedDates';
import { TraceDetailsProps } from 'types/traces';

const TraceDetails: React.FC<TraceDetailsProps> = ({
  duration,
  startTime,
  endTime,
  workflowName,
}) => {
  const { messages } = useLanguage();
  const langData = messages?.Traces;

  return (
    <div className="mt-1 flex items-center gap-2 text-form-input-text text-body-subtle">
      <p>
        {duration} {langData?.trace_details?.milli_seconds}
      </p>
      <div className="h-4 border-l-2 border-form-control-border-disabled" />
      <p>
        {formatDateMonthTime(startTime)} - {formatDateMonthTime(endTime)}
      </p>
      {workflowName && (
        <>
          <div className="h-4 border-l-2 border-form-control-border-disabled" />
          <p>
            {messages?.PromptFilters?.multi_select?.workflow}: {workflowName}
          </p>
        </>
      )}
    </div>
  );
};

export { TraceDetails };
