/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

export const prompts = {
  PROMPT_LOADING: 'PROMPT_LOADING',
  FETCH_PROMPTS: 'FETCH_PROMPTS',
  PROMPT_ERROR: 'PROMPT_ERROR',
  PROMPT_QUERY_PARAMS: 'PROMPT_QUERY_PARAMS',
};

export const traces = {
  TRACES_LOADING: 'TRACES_LOADING',
  TRACE_SPAN_LOADING: 'TRACE_SPAN_LOADING',
  FETCH_TRACES: 'FETCH_TRACES',
  TRACES_ERROR: 'TRACES_ERROR',
  TRACES_QUERY_PARAMS: 'TRACES_QUERY_PARAMS',
  FETCH_TRACE_SPAN_DETAILS: 'FETCH_TRACE_SPAN_DETAILS',
};

export const ALL_TIME_START_DATE = '2024-01-01';
