/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import { useLanguage } from '@/providers/LanguageProvider';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import { Preset } from './types';

interface PresetSelectProps {
  presets: Preset[];
  selectedPreset?: string;
  onPresetChange: (preset: string) => void;
}

export const PresetSelect: React.FC<PresetSelectProps> = ({
  presets,
  selectedPreset,
  onPresetChange,
}) => {
  const { messages } = useLanguage();
  const langData = messages?.PromptFilters;
  return (
    <Select defaultValue={selectedPreset} onValueChange={onPresetChange}>
      <SelectTrigger className="mx-auto mb-2 w-[180px]">
        <SelectValue placeholder={langData?.date_range.select} />
      </SelectTrigger>
      <SelectContent>
        {presets.map((preset) => (
          <SelectItem key={preset.name} value={preset.name}>
            {preset.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
