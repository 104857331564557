/* Copyright (C) Okahu Inc 2023-2024. All rights reserved.*/
// https://github.com/sersavan/shadcn-multi-select-component

'use client';

import { forwardRef, KeyboardEvent, useEffect, useState } from 'react';
import { useLanguage } from '@/providers/LanguageProvider';
import { cva } from 'class-variance-authority';
import {
  CheckIcon,
  ChevronDown,
  MinusIcon,
  WandSparkles,
  XIcon,
} from 'lucide-react';
import { MultiSelectProps } from 'types/multi-select';

import { cn } from '@/lib/utils';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Separator } from '@/components/ui/separator';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

import { ScrollArea } from '../ui/scroll-area';

/**
 * Variants for the multi-select component to handle different styles.
 * Uses class-variance-authority (cva) to define different styles based on "variant" prop.
 */
export const multiSelectVariants = cva('m-1', {
  variants: {
    variant: {
      default: 'border-foreground/10 text-foreground bg-card hover:bg-card/80',
      secondary:
        'border-foreground/10 bg-secondary text-secondary-foreground hover:bg-secondary/80',
      destructive:
        'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
      inverted: 'inverted',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export const MultiSelect = forwardRef<HTMLButtonElement, MultiSelectProps>(
  (
    {
      options,
      onValueChange,
      variant,
      defaultValue = [],
      placeholder,
      animation = 0,
      maxCount = 2,
      modalPopover = false,
      className,
      loading,
      allCheckbox,
      ...props
    },
    ref
  ) => {
    const [selectedValues, setSelectedValues] =
      useState<string[]>(defaultValue);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    const { messages } = useLanguage();
    const langData = messages?.PromptFilters;

    useEffect(() => {
      if (!loading) setSelectedValues(defaultValue);
    }, [loading]);

    const enter = 'Enter';
    const backspace = 'Backspace';

    const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === enter) {
        setIsPopoverOpen(true);
      } else if (event.key === backspace && !event.currentTarget.value) {
        const newSelectedValues = [...selectedValues];
        newSelectedValues.pop();
        setSelectedValues(newSelectedValues);
        onValueChange(newSelectedValues);
      }
    };

    const toggleOption = (option: string) => {
      const newSelectedValues = selectedValues.includes(option)
        ? selectedValues.filter((value) => value !== option)
        : [...selectedValues, option];
      setSelectedValues(newSelectedValues);
      onValueChange(newSelectedValues);
    };

    const handleClear = () => {
      setSelectedValues([]);
      onValueChange([]);
    };

    const handleTogglePopover = () => {
      setIsPopoverOpen((prev) => !prev);
    };

    const toggleAll = () => {
      if (selectedValues.length === options.length) {
        handleClear();
      } else {
        const allValues = options.map((option) => option.value);
        setSelectedValues(allValues);
        onValueChange(allValues);
      }
    };

    return (
      <Popover
        open={isPopoverOpen}
        onOpenChange={setIsPopoverOpen}
        modal={modalPopover}
      >
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            {...props}
            onClick={handleTogglePopover}
            className={cn(
              'flex h-auto min-h-10 w-full items-center justify-between rounded-sm border border-form-control-border-rest bg-inherit p-1 hover:bg-inherit',
              className
            )}
            disabled={loading}
          >
            {selectedValues.length > 0 ? (
              <div className="flex w-full items-center justify-between">
                {allCheckbox && selectedValues.length === options.length ? (
                  <span className="mx-2.5">{langData?.multi_select.all}</span>
                ) : (
                  <div className="flex flex-wrap items-center">
                    {selectedValues.slice(0, maxCount).map((value) => {
                      const option = options.find((o) => o.value === value);
                      const IconComponent = option?.icon;
                      const wordLen = option?.label.length;
                      return (
                        <Badge
                          key={option?.id}
                          className={cn(
                            'max-w-[140px] rounded bg-[#0A343C] text-white hover:bg-[#0A343C] ',

                            isAnimating ? 'animate-bounce' : '',
                            multiSelectVariants({ variant })
                          )}
                          style={{ animationDuration: `${animation}s` }}
                        >
                          {IconComponent && (
                            <IconComponent className="mr-2 size-4" />
                          )}

                          {wordLen && wordLen > 21 ? (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <span
                                    className={cn(
                                      wordLen && wordLen > 21 && 'truncate'
                                    )}
                                  >
                                    {option?.label}
                                  </span>
                                </TooltipTrigger>
                                <TooltipContent>{option?.label}</TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          ) : (
                            <span>{option?.label}</span>
                          )}
                          <XIcon
                            className="ml-2 size-3 shrink-0 cursor-pointer text-form-control-border-rest"
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              toggleOption(value);
                            }}
                          />
                        </Badge>
                      );
                    })}
                    {selectedValues.length > maxCount && (
                      <Badge
                        className={cn(
                          'rounded bg-transparent px-0 text-foreground hover:bg-transparent',
                          isAnimating ? 'animate-bounce' : '',
                          multiSelectVariants({ variant })
                        )}
                        style={{ animationDuration: `${animation}s` }}
                      >
                        {`+ ${selectedValues.length - maxCount} more`}
                      </Badge>
                    )}
                  </div>
                )}
                <div className="flex items-center justify-between">
                  <XIcon
                    strokeWidth={2}
                    className="mx-2 size-6 cursor-pointer text-body-secondary"
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      handleClear();
                    }}
                  />
                  <Separator
                    orientation="vertical"
                    className="h-full min-h-4 bg-form-control-border-disabled"
                  />
                  <ChevronDown
                    strokeWidth={2}
                    className="mx-2 size-6 cursor-pointer text-body-secondary"
                  />
                </div>
              </div>
            ) : (
              <div className="mx-auto flex w-full items-center justify-between">
                <span className="mx-3 text-sm text-[#8C9EA1]">
                  {loading ? messages?.General?.loading : placeholder}
                </span>
                <ChevronDown className="mx-2 size-6 cursor-pointer text-body-secondary" />
              </div>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="-mt-2 w-auto p-0"
          align="start"
          sideOffset={0}
          onEscapeKeyDown={() => setIsPopoverOpen(false)}
        >
          <Command className="rounded-sm rounded-b-none bg-white">
            <CommandInput
              placeholder={`${langData?.multi_select.placeholder}`}
              onKeyDown={handleInputKeyDown}
              iconColor="text-form-input-disabled"
              className="text-[#323130] placeholder:text-[#8C9EA1]"
            />
            <CommandList className="bg-white">
              <CommandEmpty>
                {langData?.multi_select.no_results_found}
              </CommandEmpty>
              {allCheckbox && (
                <CommandGroup className="pb-0 pt-1 text-[#323130]">
                  <CommandItem
                    key="all"
                    onSelect={toggleAll}
                    className="cursor-pointer data-[selected=true]:bg-[#EDEBE9] data-[selected=true]:text-[#323130] "
                  >
                    <div
                      className={cn(
                        'mr-2 flex size-4 items-center justify-center rounded-sm bg-primary text-primary-foreground',
                        selectedValues.length === 0 &&
                          'border border-[#323130] bg-transparent opacity-50 [&_svg]:invisible'
                      )}
                    >
                      {selectedValues.length === options.length ? (
                        <CheckIcon className="size-4 rounded-sm border border-jade bg-jade text-white" />
                      ) : (
                        <MinusIcon className="size-4 rounded-sm border border-jade bg-jade text-white" />
                      )}
                    </div>
                    <span>({langData?.multi_select.select_all})</span>
                  </CommandItem>
                </CommandGroup>
              )}
              <CommandGroup className="text-[#323130]">
                <ScrollArea className="h-40">
                  {options.map((option) => {
                    const isSelected = selectedValues.includes(option.value);
                    return (
                      <CommandItem
                        key={option.value}
                        onSelect={() => toggleOption(option.value)}
                        className="cursor-pointer data-[selected=true]:bg-[#EDEBE9] data-[selected=true]:text-[#323130]"
                      >
                        <div
                          className={cn(
                            'mr-2 flex size-4 items-center justify-center rounded-sm border border-[#323130]',
                            isSelected
                              ? 'border border-jade bg-jade text-primary-foreground'
                              : 'opacity-50 [&_svg]:invisible'
                          )}
                        >
                          <CheckIcon className="size-4 border border-jade text-white" />
                        </div>
                        {option.icon && (
                          <option.icon className="mr-2 size-4 text-muted-foreground" />
                        )}
                        <span>{option.label}</span>
                      </CommandItem>
                    );
                  })}
                </ScrollArea>
              </CommandGroup>
            </CommandList>
          </Command>
          <div className="rounded-b-sm bg-white">
            <Separator className="bg-[#C1C9CF]" />
            <div className="text-btn-label text-[#0A2D3A]">
              <div className="flex items-center justify-between">
                {selectedValues.length > 0 && (
                  <>
                    <Button
                      variant={'ghost'}
                      onClick={handleClear}
                      className="mx-2 my-1 h-7 flex-1 cursor-pointer justify-center hover:bg-[#EDEBE9] hover:text-[#0A2D3A]"
                    >
                      {langData?.multi_select.clear}
                    </Button>
                    <Separator
                      orientation="vertical"
                      className="h-full min-h-4 bg-line-secondary"
                    />
                  </>
                )}
                <Button
                  variant={'ghost'}
                  onClick={() => setIsPopoverOpen(false)}
                  className="mx-2 my-1 h-7 flex-1 cursor-pointer justify-center hover:bg-[#EDEBE9] hover:text-[#0A2D3A]"
                >
                  {messages?.General?.close}
                </Button>
              </div>
            </div>
          </div>
        </PopoverContent>
        {animation > 0 && selectedValues.length > 0 && (
          <WandSparkles
            className={cn(
              'my-2 h-3 w-3 cursor-pointer bg-background text-foreground',
              isAnimating ? '' : 'text-muted-foreground'
            )}
            onClick={() => setIsAnimating(!isAnimating)}
          />
        )}
      </Popover>
    );
  }
);

MultiSelect.displayName = 'MultiSelect';
