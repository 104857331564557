/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import { useEffect } from 'react';
import { UseTraceSpanDetailsProps } from 'types/traces';

import { useTraces } from '@/hooks/useTraces';

export const useTraceSpanDetails = ({
  traceId,
  workflowName,
}: UseTraceSpanDetailsProps) => {
  const { state, fetchTraceSpanDetails } = useTraces();
  const { traceSpanDetails } = state;

  useEffect(() => {
    if (traceId && workflowName) {
      fetchTraceSpanDetails(traceId, workflowName);
    }
  }, [traceId, workflowName]);

  return { traceSpanDetails };
};
