/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import React, { FC, useEffect, useState } from 'react';
import { useLanguage } from '@/providers/LanguageProvider';
import * as d3 from 'd3';

import { findMaxDuration, formatData } from './services/data.service';
import {
  chevronDownIcon,
  chevronRightIcon,
  collapseIcon,
  expandIcon,
} from './svg';

export type DataModel = {
  id: string;
  startTime: string;
  startms?: number;
  endTime: string;
  parentId?: string;
  duration: number;
  label: string;
  color: string;
  children?: DataModel[];
  isFiltered?: boolean;
  entityCount?: number;
};

type ChartInternalOptions = {
  svg?: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;
  dateFormat?: (date: Date) => string;
  linearScale?: d3.ScaleLinear<number, number, never>;
  data: DataModel[];
  width: number;
};

interface InputProps {
  data: DataModel[];
  onClick?: (id: string) => void;
}

const ROW_HEIGHT = 50;

export const GanttChart: FC<InputProps> = ({ data, onClick }) => {
  const [isColapsed, setIsCollapsed] = useState<boolean>(false);
  const [rowCollapsed, setRowCollapsed] = useState<{
    [key: string]: boolean;
  }>({});
  const { messages } = useLanguage();

  let visibleIndex = -1;

  useEffect(
    () => {
      setTimeout(() => {
        const container = document.getElementById('cw-gantt-chart-container');
        const containerWidth = container?.clientWidth;
        const pdata = formatData([...data]);

        const options: ChartInternalOptions = {
          data: pdata,
          width: containerWidth || 800,
        };
        d3.selectAll('#svg-wrapper').remove();
        const svg = d3
          .selectAll('.svg')
          //.selectAll('svg')
          .append('svg')
          .attr('width', containerWidth || 800)
          .attr(
            'height',
            getVisibleDataLength(options, pdata) * ROW_HEIGHT + ROW_HEIGHT
          )
          .attr('id', 'svg-wrapper');

        const dateFormat = d3.utcFormat('%Y-%m-%d');

        options.svg = svg;
        options.dateFormat = dateFormat;
        const linearScale = d3.scaleLinear().domain([0, 100]).range([100, 800]);
        options.linearScale = linearScale;
        startDrawing(options);
      }, 10);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowCollapsed, data]
  );

  const onChevronClick = (id: string) => {
    rowCollapsed[id] = !rowCollapsed[id];
    setRowCollapsed({ ...rowCollapsed });
    if (!rowCollapsed[id]) {
      setIsCollapsed(false);
    }
  };

  const startDrawing = (options: ChartInternalOptions) => {
    if (options && options.svg) {
      // options.internal.svg.selectAll('.chartBar').remove();
      // options.internal.svg.selectAll('rect').remove();
      drawAxis(options);
      drawHeaderColumn(options);
      drawVerticalLines(options);
      drawRects(options);

      drawEndLine(options);

      d3.selectAll('text').style('fill', '#8C9EA1');
      d3.selectAll('.lineOverride').style('stroke', '#FFFFFF');
      d3.selectAll('.lineHighlight').style('stroke', '#8C9EA1');
      d3.selectAll('.textLink').style('fill', '#34BCAF');
      d3.selectAll('.cwgcTextDark').style('fill', 'black');
    }
  };

  const drawHeaderColumn = (options: ChartInternalOptions) => {
    options.svg
      ?.append('line')
      .attr('class', 'lineOverride')
      .style('stroke', 'black')
      .attr('stroke-opacity', 0.12)
      .attr('x1', 0)
      .attr('y1', 50)
      .attr('x2', 75)
      .attr('y2', 50);
    d3.select('#expandcollapse').remove();

    const onClickEvent = (val: boolean) => {
      options.data.forEach((d) => {
        rowCollapsed[d.id] = val;
        setRowCollapsed({ ...rowCollapsed });
      });
      setIsCollapsed(val);
    };

    drawColExpandIcon(4, 29, options, 15, onClickEvent);
  };

  const drawChevron = (
    x: number,
    y: number,
    options: ChartInternalOptions,
    rotate: boolean,
    length: number,
    onClick: (id: string) => void,
    d: DataModel
  ) => {
    const onChevRightClicked = () => {
      onClick(d.id);
    };
    if (rotate) {
      chevronRightIcon(
        x,
        y,
        options.svg,
        length,
        onChevRightClicked,
        '#8C9EA1'
      );
    } else {
      chevronDownIcon(x, y, options.svg, length, onChevRightClicked, '#8C9EA1');
    }
  };

  const drawColExpandIcon = (
    x: number,
    y: number,
    options: ChartInternalOptions,
    size: number,
    onClick: (state: boolean) => void
  ) => {
    const onExpandClicked = () => {
      onClick(false);
    };
    const onCollapseClicked = () => {
      onClick(true);
    };

    if (!isColapsed) {
      collapseIcon(x, y, options.svg, size, onCollapseClicked, '#8C9EA1');
    } else {
      expandIcon(x, y, options.svg, size, onExpandClicked, '#8C9EA1');
    }
  };

  const drawAxis = (options: ChartInternalOptions) => {
    // Calculate the max duration and add dynamic padding
    const maxDuration = findMaxDuration(options.data);
    const padding = Math.ceil(maxDuration * 0.1); // 10% padding
    // Create the scale
    const x = d3
      .scaleLinear()
      .domain([0, maxDuration + padding])
      .range([75, options.width]); // This is where the axis is placed: from 100px to 800px
    options.linearScale = x;
    const xAxis = d3.axisTop(x).tickFormat((d) => `${d}ms`);

    // Draw the axis
    options.svg
      ?.append('g')
      .attr('transform', 'translate(0,50)') // This controls the vertical position of the Axis
      .attr('class', 'axisLight')
      .call(xAxis);

    const y = d3
      .scaleLinear()
      .domain([0, options.data.length + 1]) // This is what is written on the Axis: from 0 to 100
      .range([0, getVisibleDataLength(options) * 50 + 20]); // This is where the axis is placed: from 100px to 800px

    // Draw the axis
    options.svg
      ?.append('g')
      .attr('transform', 'translate(0,50)') // This controls the vertical position of the Axis
      .call(d3.axisLeft(y))
      .attr('class', 'axisLight');
  };

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  const drawRow = (
    d: DataModel,
    options: ChartInternalOptions,
    depth: number = 0
  ) => {
    const shoudlBeInvisible = ifAnyParentIsCollapsed(d, options);
    const startPositionY = 50;
    const barThickness = 10;
    visibleIndex = shoudlBeInvisible ? visibleIndex : visibleIndex + 1;
    // Rendering the bars
    const barStat: { [key: string]: number } = {
      topY: startPositionY + (visibleIndex - 1) * ROW_HEIGHT,
      x: getXPointOnChart(options, d.startms || 0),
      y: startPositionY + (visibleIndex - 1) * ROW_HEIGHT + 10,
      height: shoudlBeInvisible ? 0 : barThickness,
      width: getXPointOnChart(options, d.duration) - 75,
    };
    if (depth === 0) {
      options.svg
        ?.append('rect')
        .attr('class', 'chartBar')
        .attr('x', 0)
        .attr('y', barStat.topY)
        .attr(
          'width',
          options.linearScale ? options.linearScale.domain()[1] : 0
        )
        .attr('height', ROW_HEIGHT)
        .attr('fill', 'rgba(255,255,255,0.1)');
    }

    // Apply opacity based on filtered state
    const opacity = d.isFiltered ? 0.3 : 1;
    const cursor = onClick && !d.isFiltered ? 'pointer' : 'default';

    const bar = options.svg
      ?.append('rect')
      .attr('class', 'chartBar')
      .attr('x', barStat.x)
      .attr('y', barStat.y)
      .attr('width', barStat.width < 5 ? 5 : barStat.width)
      .attr('height', barStat.height)
      .attr('fill', d.color)
      .style('opacity', opacity)
      .style('cursor', cursor);

    options.svg
      ?.append('line')
      .attr('class', 'lineOverride')
      .attr('x1', 0)
      .attr('stroke-opacity', 0.12)
      .attr('y1', barStat.topY + 50)
      .attr(
        'x2',
        getXPointOnChart(options, findMaxDuration(options.data)) + 200
      )
      .attr('y2', barStat.topY + 50)
      .style('visibility', shoudlBeInvisible ? 'hidden' : 'visible');
    bar?.on('click', function () {
      if (onClick) {
        onClick(d.id);
      }
    });

    const labelText = `${d.label} | ${d.duration}${
      messages?.Traces?.trace_details?.milli_seconds
    } ${
      d.entityCount
        ? `| ${d.entityCount} ${messages?.Traces?.trace_span_details?.trace_span_entities}`
        : ''
    }`;

    // Rendering texts
    const renderedText = options.svg
      ?.append('text')
      .attr('class', 'normalText')
      .text(labelText)
      .attr('x', barStat.x + 2)
      .attr('y', barStat.y + 25)
      .style('max-width', 100)
      .style('break-word', 'break-all')
      .attr('text-anchor', 'right')
      .attr('font-size', 12)
      .style('visibility', shoudlBeInvisible ? 'hidden' : 'visible')
      .style('opacity', opacity);

    const bbox = renderedText?.node()?.getBBox();
    const currentDomain = options.linearScale?.domain();
    const currentRange = options.linearScale?.range();
    const textEndLocation =
      (bbox?.x || 0) + getXPointOnChart(options, bbox?.width || 0);
    if (textEndLocation > (currentRange ? currentRange[1] : 800)) {
      // options.internal.linearScale.domain([0, currentDomain[1] + (bbox?.width || 0)]);
      renderedText?.attr(
        'x',
        getXPointOnChart(options, currentDomain ? currentDomain[1] : 800) -
          (bbox?.width || 0) -
          10
      );
    }

    if (d.children && d.children.length) {
      if (!rowCollapsed[d.id])
        d.children.forEach((c) => {
          drawRow(c, options, depth + 1);
        });

      if (!shoudlBeInvisible) {
        drawChevron(
          25 + depth * 3,
          barStat.y + 10,
          options,
          rowCollapsed[d.id],
          10,
          onChevronClick,
          d
        );
        // Rendering count
        const text = options.svg
          ?.append('text')
          .attr('class', 'normalText')
          .text(`${d.children.length}`)
          .attr('x', 40 + depth * 3)
          .attr('y', barStat.y + 19)
          .attr('text-anchor', 'right')
          .attr('cursor', 'pointer')
          .attr('font-size', 12)
          .style('transform', 'rotate(90)');
        text?.on('click', function () {
          onChevronClick(d.id);
        });
      }
    }
  };

  const drawRects = (options: ChartInternalOptions) => {
    d3.selectAll('.chartBar').remove();
    visibleIndex = 0;
    options.data.forEach((d) => {
      // Drawing bars
      drawRow(d, options);
    });
  };

  const drawVerticalLines = (options: ChartInternalOptions) => {
    options.svg
      ?.append('line')
      .attr('class', 'lineOverride')
      .style('stroke', 'black')
      .attr('stroke-opacity', 0.12)
      .attr('x1', 75)
      .attr('y1', 50)
      .attr('x2', 75)
      .attr('y2', getVisibleDataLength(options) * 50 + 57);

    options.linearScale?.ticks().forEach((tick) => {
      options.svg
        ?.append('line')
        .attr('class', 'lineOverride')
        .style('stroke', 'black')
        .attr('stroke-opacity', 0.12)
        .attr('x1', getXPointOnChart(options, tick.valueOf()))
        .attr('y1', 50)
        .attr('x2', getXPointOnChart(options, tick.valueOf()))
        .attr('y2', getVisibleDataLength(options) * 50 + 57);
    });
    options.svg
      ?.append('line')
      .attr('class', 'lineOverride')
      .style('stroke', 'black')
      .attr('stroke-opacity', 0.12)
      .attr(
        'x1',
        getXPointOnChart(
          options,
          options.linearScale ? options.linearScale?.domain()[1] : 0
        )
      )
      .attr('y1', 50)
      .attr(
        'x2',
        getXPointOnChart(
          options,
          options.linearScale ? options.linearScale?.domain()[1] : 0
        )
      )
      .attr('y2', getVisibleDataLength(options) * 50 + 57);
  };

  const drawEndLine = (options: ChartInternalOptions) => {
    options.svg
      ?.append('line')
      .attr('class', 'lineHighlight')
      .attr('x1', getXPointOnChart(options, findMaxDuration(options.data)))
      .attr('y1', 30)
      .attr('x2', getXPointOnChart(options, findMaxDuration(options.data)))
      .attr('y2', getVisibleDataLength(options) * 50 + 57);

    const textElement = options.svg
      ?.append('text')
      .attr('class', 'cwgcTextDark')
      .attr('x', getXPointOnChart(options, findMaxDuration(options.data)))
      .attr('y', 40)
      .style('text-anchor', 'middle')
      .style('font-size', '11px')
      .style('font-weight', '500')
      .style('stroke', 'back')
      .text(`${findMaxDuration(options.data)}ms`);

    const width = textElement?.node()?.getComputedTextLength();
    textElement?.remove();
    options.svg
      ?.append('rect')
      .attr(
        'x',
        getXPointOnChart(options, findMaxDuration(options.data)) -
          (width || 0) / 2 -
          8
      )
      .attr('y', 26)
      .attr('width', (width || 0) + 16)
      .attr('height', 21)
      .attr('rx', 3)
      .attr('stroke', 'none')
      .style('fill', '#C1C9CF');

    options.svg
      ?.append('text')
      .attr('class', 'cwgcTextDark')
      .attr('x', getXPointOnChart(options, findMaxDuration(options.data)))
      .attr('y', 40)
      .style('text-anchor', 'middle')
      .style('font-size', '11px')
      .style('font-weight', '500')
      .style('stroke', 'back')
      .text(`${findMaxDuration(options.data)}ms`);
    const trianglePointer = d3.symbol().type(d3.symbolTriangle).size(25);
    options.svg
      ?.append('path')
      .attr('d', trianglePointer)
      .attr('fill', '#C1C9CFd')
      .attr('stroke', 'none')
      .attr(
        'transform',
        `translate(${getXPointOnChart(
          options,
          findMaxDuration(options.data)
        )}, 47) rotate(180)`
      );
  };

  const getXPointOnChart = (options: ChartInternalOptions, point: number) => {
    return options.linearScale ? options.linearScale(point) : 100;
  };

  const ifAnyParentIsCollapsed = (
    data: DataModel,
    options: ChartInternalOptions
  ): boolean => {
    if (!data.parentId) {
      return false;
    }
    if (rowCollapsed[data.parentId]) {
      return true;
    }
    const findParent = options.data.filter((d) => d.id === data.parentId);

    if (findParent && findParent.length) {
      return ifAnyParentIsCollapsed(findParent[0], options);
    }
    return false;
  };

  const getVisibleDataLength = (
    options: ChartInternalOptions,
    data?: DataModel[]
  ) => {
    let count = 0;
    (data || options.data).forEach((p) => {
      if (!ifAnyParentIsCollapsed(p, options)) {
        count++;
        if (p.children && p.children.length) {
          count = count + getVisibleDataLength(options, p.children);
        }
      }
    });
    return count;
  };

  return (
    <div id="cw-gantt-chart-container">
      <div className="svg"></div>
      <div id="tag"></div>
    </div>
  );
};
