import { DataModel } from '../gantt-chart';

export const formatData = (data: DataModel[]) => {
  let res: DataModel[] = [];
  const children: DataModel[] = [];
  const updatedData = updateStartTime(data);
  updatedData.forEach((d) => {
    if (d.parentId) {
      children.push(d);
    }

    res.push(d);
  });

  res = list_to_tree(res);
  return updateStartTime(res);
};

const list_to_tree = (list: DataModel[]) => {
  const map: { [key: string]: number } = {};
  let node: DataModel;
  const roots: DataModel[] = [];
  for (let i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }
  for (let i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentId) {
      // if you have dangling branches check that map[node.parentId] exists
      const mapNode = map[node.parentId];
      if (mapNode !== undefined) {
        const listNode = list[mapNode];
        if (listNode) {
          listNode.children?.push(node);
        }
      }
    } else {
      roots.push(node);
    }
  }
  return roots;
};
const updateStartTime = (data: DataModel[]) => {
  const lowestData = data.sort((a, b) =>
    a?.startTime < b?.startTime ? -1 : 1
  )[0];
  const lowestStartDate = new Date(lowestData?.startTime);

  data.forEach((d) => {
    d.startms = new Date(d.startTime).getTime() - lowestStartDate.getTime();
  });
  return data;
};

export const findMaxDuration = (data: DataModel[]) => {
  if (data && data.length) {
    const min = data.reduce((a, b) => {
      return new Date(a.startTime) < new Date(b.startTime) ? a : b;
    });
    const max = data.reduce((a, b) => {
      return new Date(a.endTime) > new Date(b.endTime) ? a : b;
    });

    const diff =
      new Date(max.endTime).getTime() - new Date(min.startTime).getTime();
    return diff;
  }
  return 100;
};
